<template>
  <div class="bankWrap">
    <div class="facilityBox">
      <div style="position: absolute;right:20px;top:125px;z-index: 999;">
        <el-tooltip placement="top">
          <div slot="content">
            1.您的企业信息用于银行开户与交易，务必真实有效<br />2.如有信息错误、修改等情况，请先联系官方客服，协调银行处理<br />3.因信息错误、擅自修改导致的损失，均由您自行承担后果!<br />4.限绑1个一般存款账户，向银行资金监管户进行充值
          </div>
          <div style="margin: 0px 20px;color: #0F5FFF;">
            <i class="el-icon-question"></i>
            <span>帮助</span>
          </div>
        </el-tooltip>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="账户信息" name="first">
          <div class="top-title">银行账户信息</div>
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="info-con">
                <div class="title">企业名称</div>
                <div class="con">{{ enterBase.EnterpriseFullName }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="info-con">
                <div class="title">企业统一社会信用代码</div>
                <div class="con">{{ enterBase.EnterpriseCreditCode }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="info-con">
                <div class="title">银行电子账户</div>
                <div class="con">{{ enterBase.AccountId }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="info-con">
                <div class="title">电子账户激活时间</div>
                <div class="con">{{ enterBase.CreateTime }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 16px;">
            <el-col :span="6">
              <div class="info-con">
                <div class="title">法人姓名</div>
                <div class="con">{{ enterBase.LegalPersonIName }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="info-con">
                <div class="title">法人身份证号</div>
                <div class="con">{{ enterBase.LegalPersonIIDCard }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="info-con">
                <div class="title">法人手机号码</div>
                <div class="con">{{ enterBase.LegalPersonlPhone }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="info-con">
                <div class="title">财务经办人姓名</div>
                <div class="con">{{ enterBase.CFOName }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 16px;">
            <el-col :span="6">
              <div class="info-con">
                <div class="title">财务经办人身份证号</div>
                <div class="con">{{ enterBase.CFOIDCardNo }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="info-con">
                <div class="title">财务经办人手机号码</div>
                <div class="con">{{ enterBase.CFOPhone }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="info-con">
                <div class="title">激活状态</div>
                <div class="con">{{ accountInfo.AuthFlag == '1' ? '已激活' : '未激活' }}</div>
              </div>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <div class="top-title">
            银行账户信息<span style="color: #333;font-size:14px">（注明:已绑定银行虚拟账户的企业一般户账号）</span>
          </div>
          <div style="display: flex;margin: 46px 0px 0px 23px;">
            <div style="position: relative;">
              <img src="@/assets/image/bankcard.png" style="width: 300px;height:172px" />
              <span
                style="position: absolute;top:103px;left:60px;font-size: 16px;color: #fff;letter-spacing:2px;">990102029012639820</span>
            </div>
            <div style="margin-left: 24px;">
              <p>
                <span>账户名称：</span>
                <span style="font-weight: bold;">江西省方众供应链管理有限公司</span>
              </p>
              <p style="margin-top: 16px;">
                <span>监管账户：</span>
                <span style="font-weight: bold;">990102029012639820</span>
              </p>
              <p style="margin-top: 16px;">
                <span>开户银行：</span>
                <span style="font-weight: bold;">江西裕民银行</span>
              </p>
              <div style="width: 240px;height: 1px;margin: 18px 0px;"></div>
              <p style="font-size: 16px;font-weight: bold;margin-top: 32px;color:#ff8b17">
                请务必使用您公司的对公账户进行充值
              </p>
              <p v-if="bindCardList.length != 0" style="font-weight: bold;margin-top: 16px;;color:#ff8b17">
                {{ bindCardList[0].acctNo }}
              </p>
              <p v-if="bindCardList.length != 0" style="margin-top: 16px;">
                {{ bindCardList[0].bankName }}
              </p>
              <p style="margin-top: 16px;">不允许使用其他账户往监管户充值</p>
            </div>
            <div style="width: 2px;height: 250px;background: #F5F5F5;margin: 0px 273px 0px 213px;"></div>
            <div style="margin-top: -50px;">
              <div>
                <span>账户余额：</span>
                <span style="color:#ff8b17;font-size:20px;font-weight:bold;">{{
                  accountInfo.ActBalance | formatMoney
                }}</span>
              </div>
              <div style="margin-top: 24px;">
                <div style="margin-bottom: 8px;">提现金额</div>
                <el-input placeholder="请输入提现金额" @input="amountInp" v-model="amount"
                  style="width:374px;text-align: center;"></el-input>
              </div>
              <div style="margin-top: 24px;">
                <span>可提现部分：</span>
                <span style="color:#ff8b17;">{{
                  accountInfo.DealAmount | formatMoney
                }}</span>
              </div>
              <div style="margin-top: 8px;color: #999;">
                <span>已冻结部分：</span>
                <span>{{
                  accountInfo.FrozeAmount | formatMoney
                }}（冻结部分需货主确认收票后可提现）</span>
              </div>
              <div style="margin-top: 24px;">
                <div style="margin-bottom: 8px;">账户号码</div>
                <el-select style="width:374px" v-model="bankValue" clearable placeholder="请选择银行账户">
                  <el-option v-for="item in bindCardList" :key="item.acctNo" :label="item.bankInfo" :value="item.acctNo">
                  </el-option>
                </el-select>
              </div>
              <div style="margin-top: 24px;">
                <div style="margin-bottom: 8px;">验证码</div>
                <el-input v-model="smsCode" style="width: 262px" placeholder="请输入验证码"></el-input>
                <el-button style="width:128px;margin-left:8px;" class="btn-send" type="primary" @click="getCode"
                  :disabled="!verifyCooling">
                  {{
                    verifyCoolingTime == 0 || verifyCoolingTime == 60
                    ? "获取验证码"
                    : verifyCoolingTime + "s后重新获取"
                  }}
                </el-button>
              </div>
              <div class="btn">
                <el-button type="primary" @click="bankClick('fetch')" :disabled="isWithdraw" :icon="bntIcon">提 现
                </el-button>
                <el-button type="primary" plain disabled size="small" :icon="bntIcon" @click="bankClick('full')">充 值
                </el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="银行账户管理" name="second">
          <el-alert style="margin:0px 0px 20px;" type="warning" :closable="false">
            <span slot="title">
              <p>温馨提示：</p>
              <p>
                1、根据您提交的企业信息，在银行/第三方进行审核与注册，务必保证信息真实、有效！
              </p>
              <p>
                2、如有信息错误、修改等情况，请先联系官方客服，协调银行/第三方支付方共同处理！
              </p>
              <p>3、因信息错误、擅自修改导致的损失，均由您自行承担后果！</p>
              <p>4、限绑1个一般存款账户,向银行资金监管户进行充值：</p>
              <p>账户名称：江西省方众供应链管理有限公司</p>
              <p>监管户账号：990102029012639820</p>
              <p>开户银行：江西裕民银行</p>
              <p>官方客服：400-076-8000</p>
            </span>
          </el-alert>
          <el-button type="primary" :icon="dialogData.modelIcon" disabled @click="operateBank('add')">绑定银行账户</el-button>
          <el-button type="primary" :icon="dialogData.modelIcon" disabled @click="operateBank('add')">解绑银行账户</el-button>
          <div style="box-size:border-box">
            <div class="card_box">
              <div class="card_item" v-for="item of bindCardList" :key="item.id">
                <div class="card_detail">
                  <div class="card_img">
                    <img src="@/assets/image/card_logo.png" />
                  </div>
                  <div class="card_title">
                    <p class="card_name">{{ item.acctNo }}</p>
                    <p style="font-size: 16px">{{ item.bankName }}</p>
                    <p class="card_type">{{ item.acctName }}</p>
                  </div>
                </div>
                <div class="card_number">{{ item.BankerAccount }}</div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="收支明细" name="detail">
          <!-- 搜索栏 -->
          <el-form inline :model="searchForm" label-width="110px">
            <el-form-item label="业务类型" prop="InvoiceType">
              <el-select v-model="searchForm.TaskTypeID" placeholder="请选择业务类型" clearable @clear="changeClear()"
                @change="changetask($event)" style="width: 200px">
                <el-option v-for="item in tasklist" :key="item.TaskTypeID" :label="item.TaskTypeName"
                  :value="item.TaskTypeID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="场景类型" prop="TaskSceneTypeID">
              <el-select v-model="searchForm.sceneID" clearable placeholder="请先选择  “业务类型”"
                @visible-change="changeValue($event)" @change="searchChange()" style="width: 200px">
                <el-option v-for="item in scenelist" :key="item.TaskSceneTypeID" :label="item.TaskSceneName"
                  :value="item.TaskSceneTypeID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发票类型" prop="InvoiceType">
              <el-select v-model="searchForm.InvoiceType" clearable placeholder="请选择发票类型" @change="searchChange()"
                style="width: 240px">
                <el-option v-for="item in ratelist" :key="item.Code" :label="item.Name" :value="item.Code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收支类型" prop="transType">
              <el-select v-model="searchForm.transType" clearable placeholder="请选择收支类型" @change="searchChange()"
                style="width: 200px">
                <el-option v-for="item in translist" :key="item.Code" :label="item.Name" :value="item.Code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="货主单位" prop="OwnerName">
              <el-input style="width: 200px" v-model="searchForm.OwnerName" placeholder="输入货主单位"></el-input>
            </el-form-item>
            <div>
              <el-form-item label="开票申请编号" prop="OrderNo">
                <el-input style="width: 200px" v-model="searchForm.OrderNo" placeholder="输入开票申请编号"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="Remark">
                <el-input style="width: 200px" v-model="searchForm.Remark" placeholder="输入备注"></el-input>
              </el-form-item>
              <el-form-item label="明细时间" prop="field3">
                <el-date-picker style="width: 240px" v-model="searchForm.dateTime" type="daterange"
                  value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  @change="searchChange()">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="付款时间" prop="PayDate">
                <el-date-picker style="width: 240px" v-model="searchForm.PayDate" type="daterange"
                  value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  @change="searchChange()">
                </el-date-picker>
              </el-form-item>
              <el-form-item label-width="10px">
                <el-button type="primary" size="medium" @click="detailSearch()" icon="el-icon-search">搜索</el-button>
                <el-button type="primary" size="medium" icon="el-icon-delete" @click="detailReset()">清空</el-button>
              </el-form-item>
            </div>
          </el-form>
          <!-- 账户信息栏 -->
          <el-form inline :model="accountFrom" label-width="110px">
            <el-form-item label="虚拟账户余额">
              <el-input v-model="accountInfo.ActBalance" style="width: 220px" disabled></el-input>
            </el-form-item>
            <el-form-item label="虚拟账户账号">
              <el-input v-model="accountInfo.AccountId" style="width: 220px" disabled></el-input>
            </el-form-item>
            <el-form-item label-width="10px">
              <!-- <el-button type="primary" size="medium" disabled icon="el-icon-download">下载明细文件(由银行提供)</el-button> -->
              <el-button type="primary" size="medium" :icon="excelIcon" @click="downloadExcel()" :loading="loading">
                下载Excel表格</el-button>
              <el-button size="mini" @click="$router.back()" icon="el-icon-arrow-left" v-if="UserId">返回</el-button>
            </el-form-item>
          </el-form>
          <!-- 收支列表 -->
          <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
            <el-table-column align="center" type="index" label="序号" width="50" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="OrderNo" label="开票申请编号" width="180" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="Date" width="160" label="明细时间" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="PayDate" width="160" label="付款时间" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="TaskType" label="业务类型" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="InvoiceType" label="发票类型" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="TaskSceneType" label="场景类型" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="PaymentType" label="收支类型" width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="IncomeMoney" label="收入金额" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="color:#FF8B17;">
                  {{
                    scope.row.IncomeMoney
                    ? "￥" + scope.row.IncomeMoney
                    : ""
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="ExpMoney" label="支出金额" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="color:#FF8B17;">
                  {{
                    scope.row.ExpMoney
                    ? "￥" + scope.row.ExpMoney
                    : ""
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="Balance" label="账户结余" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="color:#FF8B17;">
                  {{
                    scope.row.Balance
                    ? "￥" + scope.row.Balance
                    : ""
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="PayMentName" label="付款人" width="150" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="PayMentAccount" label="付款账号" width="140" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="PayeeName" label="收款人" width="140" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="OwnerName" label="货主单位" width="140" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="PayeeAccount" label="收款账号" width="140" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="Remark" label="备注" width="140" fixed="right" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="ElectronicReceiptURL" label="电子回单" fixed="right" width="130">
              <template slot-scope="scope">
                <el-button type="primary" :disabled="scope.row.ElectronicReceiptURL == null ? true : false
                    " size="small" @click="uploadClick(scope.row)" icon="el-icon-download">
                  {{
                    scope.row.ElectronicReceiptURL == null
                    ? "等待生成"
                    : "下载回单"
                  }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination background class="pagination" @current-change="handleCurrentChange"
            :current-page.sync="pagination.page" :page-size="pagination.pagesize"
            layout="total, prev, pager, next, jumper" :total="pagination.total">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="转账记录" name="transferRecord" v-if="getCanTransfer == true">
          <div class="facilityBox">
            <!-- form表单 -->
            <el-form class="manageForm" :model="manageForm" :inline="true">
              <el-form-item label="企业名称：" prop="SrcUserName">
                <el-input class="fromInp" v-model="manageForm.SrcUserName" placeholder="输入企业名称"></el-input>
              </el-form-item>
              <el-form-item label="收款账号：" prop="TargetUserName">
                <el-input class="fromInp" v-model="manageForm.TargetUserName" placeholder="输入收款账号"></el-input>
              </el-form-item>
              <el-form-item label="转账时间：" prop="TaskDatetime">
                <el-date-picker v-model="manageForm.TaskDatetime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" value-format="yyyy-MM-dd" end-placeholder="结束日期"></el-date-picker>
              </el-form-item>
              <el-form-item label-width="10px">
                <el-button type="primary" size="medium" @click="detailSearch()" icon="el-icon-search">搜索</el-button>
                <el-button type="primary" size="medium" @click="detailReset" icon="el-icon-delete">清空</el-button>
              </el-form-item>
            </el-form>
            <el-table :data="transferTableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
              v-loading="loading">
              <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip></el-table-column>
              <el-table-column align="center" prop="SrcUserName" label="企业名称" show-overflow-tooltip></el-table-column>
              <el-table-column align="center" prop="Amount" label="转出金额" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div style="color:#FF8B17;">
                    {{ scope.row.Amount | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="TargetUserName" label="收款账号" show-overflow-tooltip></el-table-column>
              <el-table-column align="center" prop="Remark" label="转账备注" show-overflow-tooltip></el-table-column>
              <el-table-column align="center" prop="CreatedTime" label="转账时间" show-overflow-tooltip></el-table-column>
            </el-table>
            <div style="margin-top:10px;display: flex;align-items: center;">
              <!-- 分页 -->
              <el-pagination background class="pagination" @current-change="handleCurrentChange1"
                :current-page.sync="pagination1.page" :page-size="pagination1.pagesize"
                layout="total, prev, pager, next, jumper" :total="pagination1.total"></el-pagination>
              <div style="display:flex; margin: 0 0 0 30px;">
                <div style="color:red;font-weight: bold;">
                  转出金额总计：{{ totalMoney | formatMoney }}
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 绑定银行卡 -->
    <el-dialog class="addDialog" :visible.sync="dialogData.isQR" width="700px" :close-on-click-modal="false">
      <span slot="title" class="dialog-title">
        人脸识别
      </span>
      <el-alert type="warning" :closable="false">
        <span slot="title">
          <p>温馨提示：</p>
          <p>
            1，基于大数据平台与资金监管要求，企业财务经办人必须进行人脸识别验证；
          </p>
          <p>
            2，请您用手机端微信、百度、浏览器等扫码，进入验证页面，并完成整个验证过程；如扫码不成功，请将网址链接复制到手机浏览器中打开；
          </p>
          <p>3，人脸识别验证将连接公安、银行及有权机构相关信息库；</p>
          <p>4，完成人脸识别，且资料审核通过，用户具备作业资格；</p>
          <p>5，点击输入框，即可复制链接</p>
        </span>
      </el-alert>
      <div style="width: 100%;text-align: center;">
        <img class="QrCodeUrl" :src="ymData.QrCodeUrl" />
      </div>
      <div style="font-size:16px;" @click="copy">
        <el-form inline>
          <el-form-item label="人脸识别网址链接：">
            <el-input id="copyMy" style="width:500px;" v-model="ymData.Url" readonly></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 提现成功弹窗 -->
    <el-dialog class="addDialog" :visible.sync="dialogData.isDeposit" width="700px" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false">
      <span class="dialogText">您的请求已受理，由于资金受银行监管，实际到账时间以银行为准！{{
        dialogData.modelNum
      }}秒后自动关闭弹窗</span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getDataDict } from "@/api/common/common.js";
import {
  getYuminOut,
  AddBankCard,
  getYuminCode,
  GetUserBankerInfos,
  getYMAccount,
  accountTrans,
  downloadExcel,
  GetBaseBankInfo,
} from "@/api/finance/bank";
import {
  getTask,
  getGoodsOwnerInfo,
  getTaskScene,
} from "@/api/goodsOwner/goodsOwnerManage/basicInfo";
import { PageRecord, TotalRecord } from "@/api/TransferRecord/index";
import { _getUserId } from "@/utils/storage";
import moment from 'moment'
export default {
  data() {
    return {
      activeName: "first",
      // 选择银行账户
      options: [],
      // 已选择的银行账户
      bankValue: "",
      // 取现&充值
      moneyInput: "",
      // 弹窗参数
      dialogData: {
        isQR: false,
        modelIcon: "",
        isDeposit: false,
        modelNum: 5,
      },
      // 用户基本信息
      enterBase: {},
      // 银行账户信息
      accountInfo: "",
      // 提现金额
      amount: "",
      // 验证码
      smsCode: "",
      // 取现按钮Icon
      bntIcon: "el-icon-s-finance",
      verifyCooling: true, //是否能按下发送验证码按钮
      verifyCoolingTime: 60, //剩下多少秒才能发送验证码
      // 银行卡列表
      bindCardList: [],
      // 收支明细搜索框
      searchForm: {
        dateTime: ["", ""], //合作起始日期
        PayDate: ["", ""], //付款时间
        InvoiceType: "", //票据类型
        transType: "", //收支类型
        OrderNo: "", // 开票申请编号
        OwnerName: "", // 货主单位
        Remark: "", // 备注
        TaskTypeID: "", //业务类型
        sceneID: "", //场景类型
      },
      // 费率数据
      ratelist: [
        {
          Name: "全部",
          Code: "",
        },
      ],
      // 日期选择一个月之内
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate = minDate.getTime();
          if (maxDate) {
            this.choiceDate = "";
          }
        },
        disabledDate: (time) => {
          let choiceDateTime = new Date(this.choiceDate).getTime();
          const minTime = new Date(choiceDateTime).setMonth(
            new Date(choiceDateTime).getMonth() - 1
          );
          const maxTime = new Date(choiceDateTime).setMonth(
            new Date(choiceDateTime).getMonth() + 1
          );
          const min = minTime;
          const newDate =
            new Date(new Date().toLocaleDateString()).getTime() +
            24 * 60 * 60 * 1000 -
            1;
          const max = newDate < maxTime ? newDate : maxTime;
          //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate) {
            return time.getTime() < min || time.getTime() > max;
          }
          //若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        },
      },
      // 账户信息数据
      accountFrom: {},
      // 表格loading
      loading: false,
      // 表格数据
      tableData: [],
      //分页控件相关参数
      pagination: {
        //当前所处的页码
        page: 1,
        //每次请求的数量
        pagesize: 10,
        //总条数
        total: 0,
      },
      // 取现验证码
      codeData: "",
      // 扫码弹窗数据
      ymData: "",
      // 收支类型
      translist: [
        {
          Name: "全部",
          Code: "",
        },
      ],
      // 下载Excel图标
      excelIcon: "el-icon-download",
      // 获取业务类型
      tasklist: [
        {
          TaskTypeName: "全部",
          TaskTypeID: "",
        },
      ],
      // 场景类型
      scenelist: [],
      // 是否可以提现
      isWithdraw: true,
      // 客户账号列表跳转来的UserId
      UserId: "",
      // 筛选条件数据
      manageForm: {
        SrcUserName: "",
        TargetUserName: "",
        TaskDatetime: null,
      },
      transferTableData: [],
      pagination1: {
        //当前所处的页码
        page: 1,
        //每次请求的数量
        pagesize: 10,
        //总条数
        total: 0,
      },
      totalMoney: '', // 转出金额总计
    };
  },
  methods: {
    moment,
    //分页控件页码change事件回调
    handleCurrentChange1(e) {
      this.pagination1.page = e;
      // 获取转账记录
      this.getPageRecord();
    },
    // 获取转账记录
    getPageRecord() {
      this.loading = true;
      let data = {
        SrcUserName: this.manageForm.SrcUserName,
        TargetUserName: this.manageForm.TargetUserName,
        CreatedTimeStart: this.manageForm.TaskDatetime ? moment(this.manageForm.TaskDatetime[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
        CreatedTimeEnd: this.manageForm.TaskDatetime ? moment(this.manageForm.TaskDatetime[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pagesize
      };
      PageRecord(data)
        .then((res) => {
          this.pagination1.total = res.data.TotalCount;
          this.transferTableData = res.data.DataList;
          this.getTotalRecord()
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取转账记录统计
    async getTotalRecord() {
      await TotalRecord({
        SrcUserName: this.manageForm.SrcUserName,
        TargetUserName: this.manageForm.TargetUserName,
        CreatedTimeStart: this.manageForm.TaskDatetime ? moment(this.manageForm.TaskDatetime[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
        CreatedTimeEnd: this.manageForm.TaskDatetime ? moment(this.manageForm.TaskDatetime[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pagesize
      }).then(res => {
        this.totalMoney = res.data.Amount || ''
      })
    },
    // 提现输入金额
    amountInp(e) {
      if (e == "") {
        this.isWithdraw = true;
      } else {
        if (Number(e) > Number(this.accountInfo.DealAmount)) {
          this.$message.error("提现金额不能大于可提现金额");
          this.isWithdraw = true;
        } else {
          this.isWithdraw = false;
        }
      }
    },
    // 搜索
    searchChange() {
      this.detailSearch();
    },
    // 业务类型
    changetask(e) {
      if (e == "") {
        this.scenelist = [
          {
            TaskSceneName: "全部",
            TaskSceneTypeID: "",
          },
        ];
        this.detailSearch();
      }
      this.searchForm.sceneID = "";
    },
    // 业务类型
    changeClear(e) {
      this.searchForm.TaskTypeID = "";
    },
    // 场景类型
    changeValue(e) {
      if (e) {
        if (this.searchForm.TaskTypeID == "") return;
        // 获取场景类型
        getTaskScene({ TaskTypeID: this.searchForm.TaskTypeID }).then((res) => {
          let arr = [
            {
              TaskSceneName: "全部",
              TaskSceneTypeID: "",
            },
          ];
          arr = arr.concat(res.TaskTypeList);
          this.scenelist = arr;
        });
      }
    },
    // 下载Excel
    downloadExcel() {
      this.excelIcon = "el-icon-loading";
      this.loading = true;

      let taskType=this.tasklist.length?this.tasklist.find(x=>x.TaskTypeID== this.searchForm.TaskTypeID)?.TaskTypeName:"";
      let invoiceType=this.ratelist.length?this.ratelist.find(x=>x.Code==this.searchForm.InvoiceType)?.Name:"";
      let taskSceneType=this.scenelist.length?this.scenelist.find(x=>x.TaskSceneTypeID==this.searchForm.sceneID)?.TaskSceneName:"";
      taskType=taskType=="全部"?"":taskType;
      invoiceType=invoiceType=="全部"?"":invoiceType;
      taskSceneType=taskSceneType=="全部"?"":taskSceneType;

      let data = {
        startDate: (this.searchForm.dateTime && this.searchForm.dateTime[0]) ? this.searchForm.dateTime[0] + ' 00:00:00' : '',
        endDate: (this.searchForm.dateTime && this.searchForm.dateTime[1]) ? this.searchForm.dateTime[1] + ' 23:59:59' : '',
        payStartDate: (this.searchForm.PayDate && this.searchForm.PayDate[0]) ? this.searchForm.PayDate[0] + ' 00:00:00' : '',
        payEndDate: (this.searchForm.PayDate && this.searchForm.PayDate[1]) ? this.searchForm.PayDate[1] + ' 23:59:59' : '',
        invoiceType: invoiceType,
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pagesize,
        transType: this.searchForm.transType,
        OrderNo: this.searchForm.OrderNo,
        OwnerName: this.searchForm.OwnerName,
        Remark: this.searchForm.Remark,
        TaskType: taskType,
        SceneType: taskSceneType,
        UserID: this.UserId,
      };
      downloadExcel(data)
        .then((res) => {
          this.$notify({
            title: "下载提示",
            message: res.data,
            position: "top-right",
            type: "success",
          });
        })
        .finally(() => {
          this.excelIcon = "el-icon-download";
          this.loading = false;
        });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      // 获取收支明细
      this.accountTrans();
    },
    // 清空搜索
    detailReset() {
      if (this.activeName == 'detail') {
        this.searchForm = {
          dateTime: ["", ""],
          PayDate: ["", ""],
          InvoiceType: "",
        }
        this.pagination.page = 1
        // 获取收支明细
        this.accountTrans();
      } else {
        this.manageForm = {
          SrcUserName: "",
          TargetUserName: "",
          TaskDatetime: null,
        }
        this.pagination1.page = 1
        this.getPageRecord();
      }

    },
    // 搜索数据
    detailSearch() {
      if (this.activeName == 'detail') {
        this.pagination.page = 1;
        this.accountTrans();
      } else {
        this.pagination1.page = 1
        this.getPageRecord();
      }
    },
    /*
                                  分割线（以上为收支明细操作事件）
    */
    handleClick(tab) {
      if (tab.index == 0) {
        // 查询账户信息
        this.getYMAccount();
      } else if (tab.index == 1) {
        // 获取银行卡列表
        this.getCardList();
      } else if (tab.index == 2) {
        this.pagination.page = 1;
        // 获取收支明细
        this.accountTrans();
      } else if (tab.index == 3) {
        this.pagination1.page = 1;
        // 获取转账记录
        this.getPageRecord()
      }
    },
    //下载单个电子回单
    uploadClick(item) {
      window.open(item.ElectronicReceiptURL);
    },
    copy() {
      document.getElementById("copyMy").select();
      document.execCommand("copy");
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    // 绑定银行卡 获取验证码
    getCode() {
      //正在倒计时中直接return
      if (!this.verifyCooling) return;
      // 获取验证码
      getYuminCode().then((res) => {
        this.codeData = res.data;
        this.$message.success("验证码发送成功");
        this.verifyCooling = false;
        this.verifyCoolingTime = this.verifyCoolingTime - 1;
        //倒计时60S才能再次发送验证码
        let timer = setInterval(() => {
          this.verifyCoolingTime = this.verifyCoolingTime - 1;
          if (this.verifyCoolingTime === 0) {
            this.verifyCooling = true;
            this.verifyCoolingTime = 60;
            clearInterval(timer);
          }
        }, 1000);
      });
    },
    // 银行卡实名认证
    handleAccountNo() {
      let params = {
        accountNo: this.bindCardForm.card,
        idcard: this.getIdCard,
        name: this.getName,
      };
      getAuthBank(params).then((res) => {
        if (res.backData.msg == "验证通过") {
          this.bindCardForm.bankName = res.backData.bank;
        } else {
          this.$message.error(res.backData.msg);
        }
      });
    },
    // 获取银行卡列表
    getCardList() {
      GetUserBankerInfos().then((res) => {
        res.data.data.forEach((item) => {
          item.bankInfo = `${item.bankName}：${item.acctNo}`;
        });
        this.bindCardList = res.data.data;
      });
    },
    // 是否显示绑定/解绑弹窗
    operateBank(type) {
      this.verifyCooling = false;
      this.dialogData.modelIcon = "el-icon-loading";
      AddBankCard({ action: "checkPublicInfo" })
        .then((res) => {
          this.ymData = res.data;
          this.dialogData.isQR = true;
        })
        .finally(() => {
          this.verifyCooling = true;
          this.dialogData.modelIcon = "";
        });
    },
    // 是否显示取现/充值弹窗
    bankClick(type) {
      this.bntIcon = "el-icon-loading";
      let data = {
        acctNo: this.bankValue,
        accountId: "",
        smsNo: this.codeData,
        smsCode: this.smsCode,
        amount: this.amount,
        remark: "",
      };
      getYuminOut(data)
        .then((res) => {
          this.smsCode = "";
          this.amount = "";
          this.codeData = "";
          this.dialogData.isDeposit = true;
          let timer = setInterval(() => {
            this.dialogData.modelNum = this.dialogData.modelNum - 1;
            if (this.dialogData.modelNum == 0) {
              setTimeout(() => {
                this.dialogData.modelNum = 3;
              }, 500);
              this.dialogData.isDeposit = false;
              clearInterval(timer);
            }
          }, 1000);
          // 查询账户信息
          this.getYMAccount();
        })
        .finally(() => {
          this.bntIcon = "el-icon-s-finance";
        });
    },
    // 获取收支明细
    accountTrans() {
      this.loading = true;
      let taskType=this.tasklist.length?this.tasklist.find(x=>x.TaskTypeID== this.searchForm.TaskTypeID)?.TaskTypeName:"";
      let invoiceType=this.ratelist.length?this.ratelist.find(x=>x.Code==this.searchForm.InvoiceType)?.Name:"";
      let taskSceneType=this.scenelist.length?this.scenelist.find(x=>x.TaskSceneTypeID==this.searchForm.sceneID)?.TaskSceneName:"";
      taskType=taskType=="全部"?"":taskType;
      invoiceType=invoiceType=="全部"?"":invoiceType;
      taskSceneType=taskSceneType=="全部"?"":taskSceneType;

      let data = {
        startDate: (this.searchForm.dateTime && this.searchForm.dateTime[0]) ? this.searchForm.dateTime[0] + ' 00:00:00' : '',
        endDate: (this.searchForm.dateTime && this.searchForm.dateTime[1]) ? this.searchForm.dateTime[1] + ' 23:59:59' : '',
        payStartDate: (this.searchForm.PayDate && this.searchForm.PayDate[0]) ? this.searchForm.PayDate[0] + ' 00:00:00' : '',
        payEndDate: (this.searchForm.PayDate && this.searchForm.PayDate[1]) ? this.searchForm.PayDate[1] + ' 23:59:59' : '',
        invoiceType: invoiceType,
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pagesize,
        transType: this.searchForm.transType,
        OrderNo: this.searchForm.OrderNo,
        OwnerName: this.searchForm.OwnerName,
        Remark: this.searchForm.Remark,
        TaskType: taskType,
        SceneType: taskSceneType,
        UserID: this.UserId,
      };
      accountTrans(data)
        .then((res) => {
          this.pagination.total = res.data.TotalCount;
          this.tableData = res.data.DataList;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查询账户信息
    getYMAccount() {
      getYMAccount({
        userid: this.UserId,
      }).then((res) => {
        if (res.data) {
          this.accountInfo = res.data || {};
        }
      });
    },
  },
  computed: {
    ...mapGetters(["getName", "getIdCard", "getCanTransfer"]),
  },
  mounted() {
    if (this.UserId) {
      this.activeName = "detail";
      // 获取收支明细
      this.accountTrans();
    }
    // 获取业务类型
    getTask({}).then((res) => {
      this.tasklist = this.tasklist.concat(res.TaskTypeList);
    });
    getDataDict({ type: 20 }).then((res) => {
      this.ratelist = this.ratelist.concat(res.patterSetInfo);
    });
    // 获取收支类型
    getDataDict({ type: 44 }).then((res) => {
      this.translist = this.translist.concat(res.patterSetInfo);
    });
    // 查询账户信息
    this.getYMAccount();
    // 获取当前用户基本信息
    getGoodsOwnerInfo({}).then((res) => {
      this.enterBase = res.enterBase;
      this.enterBase.CFOName = res.ebaseInfoEx.CFOName;
      this.enterBase.CFOPhone = res.ebaseInfoEx.CFOPhone;
      this.enterBase.CFOIDCardNo = res.ebaseInfoEx.CFOIDCardNo;
      GetBaseBankInfo({}).then((res1) => {
        this.$set(this.enterBase, "AccountId", res1.data.AccountId);
        this.$set(this.enterBase, "CreateTime", res1.data.CreateTime);
      });
    });
    // 获取银行卡列表
    this.getCardList();
  },
  created() {
    this.UserId =
      this.$route.query && this.$route.query.UserId
        ? this.$route.query.UserId
        : "";
  },
};
</script>

<style lang="scss">
.bankWrap {
  .el-dialog--center .el-dialog__body {
    padding: 0 30px;
  }

  .el-input.is-disabled .el-input__inner {
    color: #333 !important;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    text-align: center;

    .dialogText {
      font-size: 16px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/style/variable.scss";

.bankWrap {
  width: 100%;

  .info-con {
    width: 265px;
    font-size: 14px;
    color: #333333;

    .title {
      margin-bottom: 8px;
    }

    .con {
      width: 374px;
      height: 36px;
      background: #f5f5f5;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      line-height: 36px;
      text-align: center;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .text {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }

  .btn {
    width: 10%;
    display: flex;
    justify-content: space-between;
    // padding-left: 39%;
    margin-top: 30px;
  }

  .bankTitle {
    text-align: center;
    margin: 30px 0;
    font-size: 24px;
  }

  .top-title {
    color: #2081ff;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    // border-bottom: 1px solid $borderGray;
    padding: 10px 0;
    margin-bottom: 10px;
    padding-left: 8px;
    font-weight: 400;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: $primary;
    }
  }

  .priceBox {
    width: 50%;
    margin: 20px auto;
  }

  .card_box {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 52vh;
    overflow: auto;

    .card_item {
      position: relative;
      border-radius: 6px;
      background: linear-gradient(-90deg, #5385f1, #70abf5);
      width: 300px;
      overflow: hidden;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 10px;

      .card_detail {
        display: flex;
        align-items: center;

        .card_title {
          color: #fff;

          .card_name {
            font-size: 18px;
          }
        }

        .card_img {
          width: 40px;
          float: left;
          margin-right: 10px;
          // background: #fff;
          border-radius: 40px;

          img {
            width: 40px;
          }
        }
      }

      .card_number {
        text-align: center;
        font-size: 18px;
        color: #fff;
        font-weight: 700;
        padding-top: 20px;
      }

      .setBind {
        position: absolute;
        bottom: 5px;
        right: 15px;
        font-size: 16px;
        color: #ffa940;
        cursor: pointer;
      }
    }
  }
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>
